<template>
  <div>
    <PageHeader title="Uit dienst" />
    <div class="tw-grid tw-grid-cols-2 tw-gap-4">
      <div class="tw-px-2.5 tw-pt-5 tw-pb-16">
      <FormulateForm
        #default="{ isLoading }"
        v-model="values"
        name="transferEntitiesAndLeads"
        class="tw-mb-8 tw-p-6 tw-bg-white tw-rounded tw-shadow-lg"
        @submit="transferPropertiesAndLeads"
      >
        <span class="tw-justify-start tw-inline-block">
          <h2 class="tw-my-0">Overdracht panden en leads</h2>
        </span>
        <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-2.5">
          <FormulateInput
            type="autocomplete"
            auto-complete-type="collaborator"
            name="fromCollaborator"
            label="Van"
            placeholder="Selecteer een medewerker"
            outer-class="tw-m-0"
            validation="required"
            :validation-messages="{
              required: 'Je moet verplicht een medewerker selecteren'
            }"
          />
        </div>
        <h3 class="tw-mt-1.5">Panden</h3>
        <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-2.5">
          <FormulateInput
            type="autocomplete"
            auto-complete-type="collaborator"
            name="toCollaborator"
            label="Naar"
            placeholder="Selecteer een medewerker"
            outer-class="tw-m-0"
            validation="required"
            :validation-messages="{
              required: 'Je moet verplicht een medewerker selecteren'
            }"
          />
        </div>
        <h3 class="tw-my-0">Leads</h3>
        <div class="tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-2.5">
          <FormulateInput
            type="autocomplete"
            auto-complete-type="collaborator"
            name="intakeCollaborator"
            label="Intake leads"
            placeholder="Selecteer een medewerker"
            outer-class="tw-m-0"
            validation="required"
            :validation-messages="{
              required: 'Je moet verplicht een medewerker selecteren'
            }"
          />
          <FormulateInput
            type="autocomplete"
            auto-complete-type="collaborator"
            name="otherCollaborator"
            label="Verkoop en/of Verhuur leads"
            placeholder="Selecteer een medewerker"
            outer-class="tw-m-0"
            validation="required"
            :validation-messages="{
              required: 'Je moet verplicht een medewerker selecteren'
            }"
          />
        </div>
        <FormulateErrors />
        <FormulateInput
          type="submit"
          :disabled="isLoading"
        >
          <i
            :class="[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]"
          />
          Overdragen
        </FormulateInput>
      </FormulateForm>
    </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'

import { mapState, mapGetters } from 'vuex'

import { successModal, questionModal } from '@/modalMessages'
import { transferEntities } from '@/services/properties'
import { getJobStatus } from '@/services/apiService'

import { poll } from '@/utils/helpers'
import { transferLeads } from '@/services/sales'

export default {
  name: 'TransferTools',
  components: {
    PageHeader
  },
  data () {
    return {
      loading: false,
      values: {}
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapGetters('account', ['collaborator']),

    isStaff () {
      return this.user.is_staff
    },
    headers () {
      return [
        { text: 'Naam', value: 'name', orderable: true },
        { text: 'Eigenaar', value: 'owner_display' },
        { text: 'Deel van', value: 'parent' },
        { text: 'Kantoren', value: 'offices' },
        { text: 'Publiek', value: 'is_public' },
        { text: 'Acties', value: 'actions' }
      ]
    }
  },
  methods: {
    async transferPropertiesAndLeads () {
      try {
        const result = await questionModal(`Weet je zeker dat je de panden en leads van ${this.values.fromCollaborator.display_name} wil overdragen aan ${this.values.toCollaborator.display_name} `)
        if (!result.value) return
        const { fromCollaborator, toCollaborator, intakeCollaborator, otherCollaborator } = this.values
        const entityResponse = await transferEntities({
          from_collaborator_id: fromCollaborator.id,
          to_collaborator_id: toCollaborator.id
        })
        const leadResponse = await transferLeads({
          from_collaborator_id: fromCollaborator.id,
          intake_leads_collaborator_id: intakeCollaborator.id,
          other_leads_collaborator_id: otherCollaborator.id
        })
        const entityPromise = poll(entityResponse?.data?.job_id, getJobStatus, 1000)
        const leadPromise = poll(leadResponse?.data?.job_id, getJobStatus, 1000)
        await Promise.all([entityPromise, leadPromise])
        await successModal('Overdracht is voltooid')
      } catch (error) {
        console.error(error)
        this.$formulate.handle(error, 'transferEntitiesAndLeads')
      }
    }
  }
}
</script>
