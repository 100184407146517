var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"title":"Uit dienst"}}),_c('div',{staticClass:"tw-grid tw-grid-cols-2 tw-gap-4"},[_c('div',{staticClass:"tw-px-2.5 tw-pt-5 tw-pb-16"},[_c('FormulateForm',{staticClass:"tw-mb-8 tw-p-6 tw-bg-white tw-rounded tw-shadow-lg",attrs:{"name":"transferEntitiesAndLeads"},on:{"submit":_vm.transferPropertiesAndLeads},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('span',{staticClass:"tw-justify-start tw-inline-block"},[_c('h2',{staticClass:"tw-my-0"},[_vm._v("Overdracht panden en leads")])]),_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-2.5"},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"collaborator","name":"fromCollaborator","label":"Van","placeholder":"Selecteer een medewerker","outer-class":"tw-m-0","validation":"required","validation-messages":{
            required: 'Je moet verplicht een medewerker selecteren'
          }}})],1),_c('h3',{staticClass:"tw-mt-1.5"},[_vm._v("Panden")]),_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-2.5"},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"collaborator","name":"toCollaborator","label":"Naar","placeholder":"Selecteer een medewerker","outer-class":"tw-m-0","validation":"required","validation-messages":{
            required: 'Je moet verplicht een medewerker selecteren'
          }}})],1),_c('h3',{staticClass:"tw-my-0"},[_vm._v("Leads")]),_c('div',{staticClass:"tw-grid md:tw-grid-cols-2 tw-gap-4 tw-my-2.5"},[_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"collaborator","name":"intakeCollaborator","label":"Intake leads","placeholder":"Selecteer een medewerker","outer-class":"tw-m-0","validation":"required","validation-messages":{
            required: 'Je moet verplicht een medewerker selecteren'
          }}}),_c('FormulateInput',{attrs:{"type":"autocomplete","auto-complete-type":"collaborator","name":"otherCollaborator","label":"Verkoop en/of Verhuur leads","placeholder":"Selecteer een medewerker","outer-class":"tw-m-0","validation":"required","validation-messages":{
            required: 'Je moet verplicht een medewerker selecteren'
          }}})],1),_c('FormulateErrors'),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading}},[_c('i',{class:[
            'fas tw-mr-1',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]}),_vm._v(" Overdragen ")])]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }